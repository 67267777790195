<template>
  <MainLayout>
    <template v-slot:navbar-title
      >{{ `${sourceObject.phone_number}` }}
    </template>
    <template v-slot:navbar-btn-wrap>
      <router-link
        v-if="urlQuery && urlQuery.id"
        :to="`editParent/` + urlQuery.id"
        append
        class="btn btn-edit btn_color_grey"
        >редактировать
      </router-link>
      <DeleteAgreementPopup
        name="Опекуна"
        :title="sourceObject.phone_number ? sourceObject.phone_number : ''"
        description="Пользователь потеряет доступ к объекту. Оплаченные доступы будут аннулированы."
        :delete-function="deleteParent"
        agreeDescription="Аннулировать платный доступ."
      />

      <UnBlockPopup
        name="Пользователя"
        v-if="
          $store.getters.getCurrentRole == 70 &&
            !(sourceObject && sourceObject.is_active)
        "
        :title="sourceObject.phone_number"
        :un-block-function="unBanUser"
      ></UnBlockPopup>
      <BlockPopup
        name="Пользователя"
        :title="sourceObject.phone_number"
        v-if="
          $store.getters.getCurrentRole == 70 &&
            sourceObject &&
            sourceObject.is_active
        "
        :block-function="banUser"
      />
    </template>

    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <div class="keep__title">
          <p class="keep__text">Общая информация</p>
        </div>
        <BasicTableWithoutHeader
          v-if="sourceObject"
          :table-data="getDataRequisites"
        >
          <template #item.4.value="{value}">
            <div>
              <span> </span>
              <span
                :style="{
                  color: value.card_code
                    ? '#95C23D !important'
                    : '#EB5C6D !important'
                }"
              >
                {{
                  value.card_is_awaited
                    ? "Оплачено, но карта не выдана "
                    : "" || value.card_code
                    ? "Выдана"
                    : "Не оплачено"
                }}
              </span>
            </div>
          </template>

          <template #item.5.value="{ value }">
            <div>
              <span
                :style="{
                  color:
                    value.actual_accesses.length > 0
                      ? '#95C23D !important'
                      : '#EB5C6D !important'
                }"
              >
                {{
                  value.actual_accesses.length > 0
                    ? (() => {
                        let access = value.actual_accesses;
                        if (access.length >= 2) {
                          access = access.find(
                            item => item.access_type === "app"
                          );
                          if (!access) {
                            return "Не оплачено";
                          }
                        } else if (access.length === 0) {
                          return "Не оплачено";
                        }
                        if (access.access_by === "paid") {
                          return (
                            "Оплачено до " +
                            moment(access.date_end * 1000).format("DD.MM.YYYY")
                          );
                        } else if (access.access_by === "trial") {
                          return (
                            "Пробный, " +
                            moment(access.date_end * 1000).format("DD.MM.YYYY")
                          );
                        } else if (access.access_by === "unpaid") {
                          return "Бесплатный доступ";
                        }
                      })()
                    : "Не оплачено"
                }}
              </span>
            </div>
          </template>

          <template
            v-if="currentRole == 31 || currentRole == 60 || currentRole == 70"
            #item.4.value="{}"
          >
            <div class="d-flex ">
              <ValidationInputField
                class="mr-4 d-flex "
                mask="###.#####"
                :disabled="input_disabled || !user_payments == 'input-requied'"
                v-model="code"
              />

              <div class="d-flex align-center">
                <div
                  style=" border-radius: 50px; color: #ffffff; padding:5px 15px;"
                  :style="{
                    background:
                      user_payments === 'paid'
                        ? '#95C23D'
                        : user_payments === 'not-paid'
                        ? '#EB5C6D'
                        : '#6D778E'
                  }"
                >
                  <p class="text-align_center">
                    {{
                      user_payments === "paid"
                        ? "Оплачена"
                        : user_payments === "not-paid"
                        ? "Не оплачена"
                        : "Ожидает ввода карты"
                    }}
                  </p>
                </div>
              </div>
              <div class="ml-auto">
                <template
                  v-if="
                    currentRole == 31 || currentRole == 60 || currentRole == 70
                  "
                >
                  <v-btn
                    type="submit"
                    @click="editParent"
                    class="btn btn-green "
                    :disabled="input_disabled || code_isEdit == false"
                  >
                    Сохранить
                  </v-btn>
                </template>
              </div>
            </div>
          </template>
          <template v-else> -</template>
        </BasicTableWithoutHeader>
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import moment from "moment";
import DeleteAgreementPopup from "@/components/popup/DeleteAgreementPopup.vue";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import BlockPopup from "@/components/popup/BlockPopup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "ParentInfosPage",
  components: {
    BlockPopup,
    UnBlockPopup,
    DeleteAgreementPopup,
    BasicTableWithoutHeader,
    SlideLayout,
    MainLayout,
    ValidationInputField
  },
  data() {
    return {
      moment: moment
    };
  },
  methods: {
    editParent() {
      this.loading = true;
      this.$store
        .dispatch("editParent", {
          id: this.$route.params.id,
          kinderId:
            localStorage.getItem("organization") != 1
              ? localStorage.getItem("organization")
              : localStorage.getItem("KindergartenId")
        })
        .then(() => {
          // this.$store.dispatch(
          //   "fetchParentById",
          //   this.$route.params.kindergartenId
          // );
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.parent)
          );
        })
        .finally(() => (this.loading = false));
    },
    banUser() {
      this.$store.dispatch("banUser", { id: this.urlQuery.id }).then(() => {
        this.$store.dispatch(
          "successNotification",
          actionMessage.ban(successMessageDictionary.parent)
        );
      });
    },
    unBanUser() {
      this.$store.dispatch("unBanUser", { id: this.urlQuery.id }).then(() => {
        this.$store.dispatch(
          "successNotification",
          actionMessage.unBan(successMessageDictionary.parent)
        );
      });
    },
    async deleteParent() {
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });

      this.$store.dispatch("deleteParent").finally(() => {
        this.$router.go(-1);
      });
    }
  },
  computed: {
    input_disabled: {
      get() {
        return this.$store.getters.getEditParent.input_disabled.value;
      }
    },
    code_isEdit: {
      get() {
        return this.$store.getters.getEditParent.code.isEdit;
      }
    },
    code: {
      get() {
        return this.$store.getters.getEditParent?.code.value;
      },
      set(newValue) {
        this.$store.commit("changeEditParent", {
          fieldName: "code",
          value: newValue
        });
      }
    },
    user_payments: {
      get() {
        return this.$store.getters.getEditParent?.user_payments.value;
      },
      set(newValue) {
        this.$store.commit("changeEditParent", {
          fieldName: "user_payments",
          value: newValue
        });
      }
    },
    description() {
      return `Опекун "${this.sourceObject.last_name} ${
        this.sourceObject.first_name
      } ${this.sourceObject.middle_name ||
        ""}" будет удалён безвозвратно. Вы действительно хотите продолжить?`;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    getDataRequisites() {
      return [
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.phone_number)
        },
        {
          text: "Пользователь",
          value: `${this.sourceObject.additional_fields[0].value} `
        },
        {
          text: "Комментарий",
          value: `${this.sourceObject.additional_fields[1].value} `
        },

        {
          text: "Доступные группы пользователей",
          value: this.sourceObject?.access_groups?.reduce(
            (firstValue, secondValue) => {
              firstValue += secondValue.title;
              firstValue += "\n";
              return firstValue;
            },
            ""
          )
        },
        {
          text: "Статус карты",
          value: this.sourceObject?.cards
            ? this.sourceObject?.cards[0]?.status_display
            : "Не оплачено"
        },
        // {
        //   text: "Карта",
        //   value: this.sourceObject?.cards ? this.sourceObject?.card_code : "-"
        // },
        {
          text: "Приложение",
          value: this.sourceObject
        }
      ];
    },
    urlQuery() {
      return {
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getParentInfos;
    },
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        }
      ];
    }
  },
  created() {
    this.$store.dispatch("prepareEditParent", {
      id: this.$route.params.id,
      kinderId:
        localStorage.getItem("organization") != 1
          ? localStorage.getItem("organization")
          : localStorage.getItem("KindergartenId")
    });
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id
    });
    this.$store.dispatch("fetchParentById", this.$route.params.kindergartenId);
  }
};
</script>

<style scoped></style>
